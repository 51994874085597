import React from 'react'
import tw from 'twin.macro'

const Protect = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.89881 8.99802C9.11005 8.93323 14.1207 6.97925 18 3.49902C21.8791 6.9799 26.8897 8.93453 32.1012 8.99982C32.2992 10.1698 32.4 11.3758 32.4 12.6016C32.4 22.0066 26.388 30.0076 18 32.9722C9.61201 30.0058 3.60001 22.0048 3.60001 12.5998C3.60001 11.3722 3.70261 10.1698 3.89881 8.99802ZM24.6726 15.6724C25.0005 15.3329 25.1819 14.8783 25.1778 14.4063C25.1737 13.9343 24.9844 13.4829 24.6507 13.1491C24.3169 12.8154 23.8655 12.6261 23.3935 12.622C22.9216 12.6179 22.4669 12.7993 22.1274 13.1272L16.2 19.0546L13.8726 16.7272C13.5331 16.3993 13.0784 16.2179 12.6065 16.222C12.1345 16.2261 11.6831 16.4154 11.3493 16.7492C11.0156 17.0829 10.8263 17.5343 10.8222 18.0063C10.8181 18.4783 10.9995 18.9329 11.3274 19.2724L14.9274 22.8724C15.265 23.2099 15.7227 23.3994 16.2 23.3994C16.6773 23.3994 17.1351 23.2099 17.4726 22.8724L24.6726 15.6724Z"
            fill="currentColor"
        />
    </svg>
)

export default Protect
