import React from 'react'

const Desktop = () => (
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.40002 8.9999C5.40002 8.04512 5.77931 7.12945 6.45444 6.45432C7.12957 5.77919 8.04525 5.3999 9.00002 5.3999H27C27.9548 5.3999 28.8705 5.77919 29.5456 6.45432C30.2207 7.12945 30.6 8.04512 30.6 8.9999V23.3999C30.6 24.3547 30.2207 25.2704 29.5456 25.9455C28.8705 26.6206 27.9548 26.9999 27 26.9999H23.004L23.2254 27.8801L24.6726 29.3273C24.9243 29.579 25.0957 29.8997 25.1651 30.2488C25.2345 30.598 25.1989 30.9598 25.0626 31.2887C24.9264 31.6175 24.6958 31.8986 24.3998 32.0964C24.1039 32.2942 23.756 32.3998 23.4 32.3999H12.6C12.2441 32.3998 11.8961 32.2942 11.6002 32.0964C11.3043 31.8986 11.0736 31.6175 10.9374 31.2887C10.8012 30.9598 10.7656 30.598 10.835 30.2488C10.9044 29.8997 11.0758 29.579 11.3274 29.3273L12.7746 27.8801L12.996 26.9999H9.00002C8.04525 26.9999 7.12957 26.6206 6.45444 25.9455C5.77931 25.2704 5.40002 24.3547 5.40002 23.3999V8.9999ZM15.7878 21.5999H9.00002V8.9999H27V21.5999H15.7878Z"
            fill="currentColor"
        />
    </svg>
)

export default Desktop
