/* eslint-disable no-nested-ternary */
import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import TopHighlights from '../../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../../components/shared/landingPages/highlightedFunctionality'
import Benefits from '../../components/shared/landingPages/benefits'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import Protect from '../../images/btw-aangifte/protect.js'
import Desktop from '../../images/btw-aangifte/desktop.js'
import Lightning from '../../images/btw-aangifte/lightning.js'
import Bulb from '../../images/btw-aangifte/bulb.js'
import Credit from '../../images/icons/credit.js'
import automatische from '../../images/btw-aangifte/automatische.png'
import benefit from '../../images/btw-aangifte/benefits.png'
import VatDeclaration from '../../images/lottieAnimations/VatDeclarationAnimation'
import { Title } from '../../components/shared/landingPages/title'
import { Accordeon } from '../../components/UI'

const topHighlightData = {
    title: `Je btw-aangifte regelen vanuit Tellow`,
    points: [
        {
            Icon: Protect,
            title: `Veilig`,
            body: `
            Omdat je een beveiligde verbinding maakt is je data altijd veilig in Tellow.
        `,
        },
        {
            Icon: Desktop,
            title: `Eenvoudig`,
            body: `
            Je btw-aangifte wordt automatisch uitgerekend op basis van je facturen en banktransacties.
        `,
        },
        {
            Icon: Lightning,
            title: `Snel`,
            body: `
            Je btw-aangifte is binnen een paar tellen geregeld. Dat scheelt een hoop tijd!
        `,
        },
        {
            Icon: Bulb,
            title: `Inzicht`,
            body: `
            Met Tellow weet je altijd precies hoeveel btw je moet afdragen. Dat geeft rust en inzicht.
        `,
        },
    ],
}

const headerData = {
    title: `Je btw-aangifte regelen`,
    text: `Geen gedoe met het regelen van je btw-aangifte. Tellow berekent automatisch hoeveel btw je moet betalen op basis van je administratie waardoor je eenvoudig je omzetbelasting kunt indienen bij de Belastingdienst.`,
    animation: <VatDeclaration />,
    textCTA: `Gebruik Tellow gratis`,
}

const highlightedFunctionalityData = {
    title: `Automatische herinneringen`,
    subtitle: `Iedere btw-periode word je eraan herinnerd dat het tijd is om de aangifte te doen. Hiermee voorkom je dat je boetes of naheffingsaanslag moet betalen.`,
    firstStepImage: automatische,
    textCTA: `Probeer het zelf`,
}

const featureData = [
    {
        Icon: Credit,
        title: `Bankrekening koppelen`,
        text: `Koppel je bankrekening aan Tellow en maak je administratie nog makkelijker. Direct inzicht in al je uitgave en inkomsten.`,
        link: `Lees meer over het koppelen van je rekening`,
        path: `koppel-je-rekening`,
    },
    {
        Icon: Credit,
        title: `Bonnen scannen`,
        text: `Scan gemakkelijk al je bonnetjes via de app. Nooit meer bonnetjes bewaren.`,
        link: `Lees meer over het scannen van bonnen`,
        path: `bonnen`,
    },
]

const benefitsData = {
    points: [
        {
            title: 'Koppeling met de Belastingdienst',
            body: 'Tellow heeft een officiële koppeling met de Belastingdienst. Hierdoor kan je binnen een paar klikken je omzetbelasting digitaal indienen.',
            links: `Doe je btw-aangifte met Tellow`,
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Btw-overzicht',
            body: 'Alle btw-bedragen van je bonnen en facturen worden automatisch in het btw-overzicht gezet. Hierdoor heb je direct alle relevante bedragen voor je btw-aangifte op een rijtje en zie je gelijk welk bedrag kan worden teruggevorderd of moet worden afgedragen.',
            links: `Maak een account`,
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Verklein de foutmarge',
            body: 'Als zzp’er wil je er zeker van zijn dat je de btw-aangifte goed doet, zodat je geen boete ontvang of geld misloopt. Tellow automatiseert alles voor je, waardoor veel fouten worden voorkomen.',
            links: `Probeer Tellow nu`,
            url: `https://app.tellow.nl/registreer#/`,
        },
    ],
    benefitImage: benefit,
    altImage: `Benefit Image`,
}

const FAQ = [
    {
        question: 'Hoeveel belasting moet je betalen als zzp’er over 2021?',
        answer: `
            Als je de AOW-leeftijd niet hebt bereikt in 2021 betaal je 37,1% belasting over een belastbaar inkomen van maximaal €68.507. Bij een hoger belastbaar inkomen betaal je 49,50% belasting.
        `,
    },
    {
        question: 'Hoeveel belasting moet je opzij zetten als zzp’er?',
        answer: `
            Het advies is om 30% van je bruto-inkomen apart te houden voor de inkomstenbelasting als je een startende kleine ondernemer of zzp’er bent.
            <br/><br/>
            Verder is het verstandig om ontvangen btw direct weg te zetten op een aparte spaarrekening. Dit geld kun je niet privé of voor investeringen in je bedrijf gebruiken.
        `,
    },
    {
        question: 'Hoeveel belasting moet je betalen als zzp’er over 2022?',
        answer: `
            Als je de AOW-leeftijd niet bereikt in 2022 betaal je 37,07% belasting over een belastbaar inkomen van maximaal €69.398. Bij een hoger belastbaar inkomen betaal je 49,50% belasting.
        `,
    },
    {
        question: 'Wat verandert er voor de zzp’er in 2022?',
        answer: `
            <ol>
                <li>Nieuwe tarieven inkomstenbelasting.</li>
                <li>Heffingskortingen omhoog.</li>
                <li>Zelfstandigenaftrek gaat verder omlaag.</li>
                <li>Afbouw tarief aftrekposten.</li>
                <li>Percentage milieu-investeringaftrek omhoog.</li>
                <li>Bijtellingspercentage elektrische auto van de zaak wijzigt.</li>
            </ol>
        `,
    },
    {
        question: 'Hoeveel belasting betaal je als eenmanszaak?',
        answer: `
            In 2020 is er een twee schijvenstelsel ingevoerd die bepaalt hoeveel belasting je moet betalen. In de eerste schijf betaal je 37,35% belasting over een inkomen van inkomsten tot €68.508. In de tweede schijf betaal je 49,50% over alles wat boven de €68.508 komt.
        `,
    },
]

const BtwAangifte = () => (
    <Layout>
        {/* Page not indexed */}
        <Meta
            keywords={['btw', 'zzp', 'tellow']}
            path="/functies/btw-aangifte"
            title="BTW Aangifte | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        {/* First Component : Header (Main title and image) */}
        <Header data={headerData} showAnimation displayImage={false} />

        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} purpleBackground />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <ComponentContainer direction="column" padding={1.5}>
                <Title>Veelgestelde vragen</Title>
                {FAQ.map(({ question, answer }) => (
                    <Accordeon key={question} question={question}>
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </Accordeon>
                ))}
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default BtwAangifte
