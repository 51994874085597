import React from 'react'
import tw from 'twin.macro'

const Lightning = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.34 1.88289C20.7054 1.99781 21.0246 2.2263 21.2512 2.53513C21.4778 2.84397 21.6 3.21704 21.6 3.60009V12.6001H28.8C29.1292 12.5999 29.4522 12.6901 29.7338 12.8608C30.0153 13.0314 30.2447 13.2761 30.3969 13.568C30.5491 13.86 30.6183 14.1881 30.597 14.5167C30.5757 14.8452 30.4646 15.1617 30.276 15.4315L17.676 33.4315C17.4567 33.7457 17.1429 33.9817 16.7802 34.1052C16.4175 34.2287 16.0249 34.2332 15.6594 34.1182C15.294 34.0031 14.9748 33.7744 14.7483 33.4654C14.5217 33.1564 14.3997 32.7832 14.4 32.4001V23.4001H7.19997C6.87072 23.4002 6.54773 23.3101 6.26616 23.1394C5.98459 22.9687 5.75523 22.7241 5.60303 22.4322C5.45083 22.1402 5.38163 21.8121 5.40295 21.4835C5.42427 21.155 5.53531 20.8385 5.72397 20.5687L18.324 2.56869C18.5436 2.25511 18.8575 2.01971 19.22 1.89671C19.5826 1.7737 19.9749 1.76949 20.34 1.88469V1.88289Z"
            fill="currentColor"
        />
    </svg>
)

export default Lightning
